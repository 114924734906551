import { Panel } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useContext, useEffect, useState } from 'react'
import { CalibrationEvents, MastsAPI } from '../../../client'
import MastContext from '../../../contexts/MastContext'
import { QUERY_OPTIONS } from '../../../services/api'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import Page from '../../shared/Page'
import SelectMasts from '../SelectMasts'
import { getCalibrationKey, GRID_SIZE } from './constants'
import PlotTimeline from './PlotTimeline'
import TabelaCalibration from './TableCalibration'

export interface ICalibrationsProps {
  sensorFilter: number | null
  setSensorFilter: (sensorFilter: number | null) => void
  usuarioFilter: string | null
  setUsuarioFilter: (usuarioFilter: string | null) => void
  eventSelected: CalibrationEvents | undefined
  setEventSelected: (calibrationEvent: CalibrationEvents | undefined) => void
  revision: number
  clearFilters: () => void
}

const Calibration = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const { mast, isLoading: mastIsLoading, sensors } = useContext(MastContext)

  const { data: calibrationEvents, isLoading: calibrationEventsIsLoading } = useQuery(
    getCalibrationKey(mast.id),
    () => MastsAPI.getCalibration({ mastId: mast.id }),
    { ...QUERY_OPTIONS, enabled: mast.id !== 0 }
  )

  const [sensorFilter, _setSensorFilter] = useState<ICalibrationsProps['sensorFilter']>(null)
  const [usuarioFilter, _setUsuarioFilter] = useState<ICalibrationsProps['usuarioFilter']>(null)
  const [eventSelected, _setEventSelected] =
    useState<ICalibrationsProps['eventSelected']>(undefined)
  const [revision, setRevision] = useState<ICalibrationsProps['revision']>(0)
  const refresh = () => setRevision(prev => prev + 1)

  useEffect(() => {
    refresh()
  }, [isNavOpen])

  const setSensorFilter = useCallback((sensorFilter: ICalibrationsProps['sensorFilter']) => {
    _setSensorFilter(sensorFilter)
    setEventSelected(undefined)
    refresh()
  }, [])

  const setUsuarioFilter = useCallback((usuarioFilter: ICalibrationsProps['usuarioFilter']) => {
    _setUsuarioFilter(usuarioFilter)
    setEventSelected(undefined)
    refresh()
  }, [])

  const setEventSelected = useCallback((calibrationEvent: ICalibrationsProps['eventSelected']) => {
    _setEventSelected(calibrationEvent)
    refresh()
  }, [])

  const clearFilters = useCallback(() => {
    setSensorFilter(null)
    setUsuarioFilter(null)
    setEventSelected(undefined)
  }, [])

  return (
    <Page
      pageName='Calibração de Sensores'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isContentLoading={calibrationEventsIsLoading}
      isLoading={mastIsLoading}
      filters={[<SelectMasts key='SelectMasts' />]}
    >
      <Panel
        className='pf-v5-u-box-shadow-sm'
        style={{
          height: calcVerticalHeight({ elementSize: GRID_SIZE, gridSize: GRID_SIZE }),
          width: '100%',
        }}
      >
        <PlotTimeline
          mast={mast}
          eventSelected={eventSelected}
          sensorFilter={sensorFilter}
          calibrationEvents={calibrationEvents}
          revision={revision}
          sensors={sensors}
        />
        <TabelaCalibration
          eventSelected={eventSelected}
          sensorFilter={sensorFilter}
          clearFilters={clearFilters}
          setEventSelected={setEventSelected}
          setSensorFilter={setSensorFilter}
          setUsuarioFilter={setUsuarioFilter}
          usuarioFilter={usuarioFilter}
          calibrationEvents={calibrationEvents ?? ([] as CalibrationEvents[])}
          isLoadingCalibrationEvents={calibrationEventsIsLoading}
        />
      </Panel>
    </Page>
  )
}

export default Calibration

import { Split, SplitItem } from '@patternfly/react-core'
import { EditIcon, Remove2Icon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { ThSortType } from '@patternfly/react-table/dist/esm/components/Table/base/types'
import { CSSProperties, useState } from 'react'
import Empty from './Empty'

interface IColumnConfig<T> {
  key: keyof T
  description: string
  formatter?: (val: any) => string
}

const EntityTable = <T extends object>({
  items,
  itemKeyName,
  columnConfig,
  selected,
  onSelect,
  onClickDelete,
  onClickEdit,
  noDataMessage = 'Nenhum dado encontrado.',
  className = '',
  isLoading = false,
  tableClassName = '',
  isCompact = true,
  actionsLabel = 'Ações',
  style,
}: {
  items: any[]
  itemKeyName: keyof T
  columnConfig?: IColumnConfig<T>[]
  selected?: T
  noDataMessage?: string
  isLoading?: boolean
  className?: string
  tableClassName?: string
  isCompact?: boolean
  enableDelete?: boolean
  enableEdit?: boolean
  onSelect?: (instance: T) => void
  onClickDelete?: (instance: T) => void
  onClickEdit?: (instance: T) => void
  style?: CSSProperties
  actionsLabel?: string
}) => {
  const hasActions = onClickDelete || onClickEdit
  const [activeSortIndex, setActiveSortIndex] = useState<number>()
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc'>()

  if (!items || items.length === 0 || isLoading) {
    return (
      <div style={{ backgroundColor: 'white' }} className={className}>
        <OuterScrollContainer style={style}>
          <InnerScrollContainer>
            {columnConfig ? (
              <Table
                variant={isCompact ? 'compact' : undefined}
                aria-label={'entity-table'}
                isStickyHeader
                className={tableClassName}
              >
                <Thead>
                  <Tr>
                    {columnConfig.map((col, i) => (
                      <Th key={`${col.description}-${i}`} className='pf-m-wrap'>
                        {col.description}
                      </Th>
                    ))}
                    {hasActions ? <Th>{actionsLabel}</Th> : null}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td colSpan={columnConfig.length + 1}>
                      <Empty title={noDataMessage} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            ) : (
              <Empty title={noDataMessage} />
            )}
          </InnerScrollContainer>
        </OuterScrollContainer>
      </div>
    )
  }

  const columns = columnConfig
    ? columnConfig
    : (Object.keys(items[0]).map(k => ({ key: k, description: k })) as IColumnConfig<T>[])

  const entityKeys = columns.map(e => e.key) as (keyof T)[]

  const onRowClick = (instance: T) => {
    if (!onSelect) {
      console.log('Must define onSelect in order to be able to select rows')
      return
    }
    onSelect(instance)
  }
  const isClickable = !!onSelect

  let sortedEntities = items

  if (activeSortIndex !== undefined) {
    sortedEntities = [...items].sort((a, b) => {
      const aValue = a[entityKeys[activeSortIndex]]
      const bValue = b[entityKeys[activeSortIndex]]

      if (typeof aValue === 'number') {
        // Numeric sort
        if (activeSortDirection === 'asc') {
          return (aValue as number) - (bValue as number)
        }
        return (bValue as number) - (aValue as number)
      } else {
        // String sort
        if (activeSortDirection === 'asc') {
          return String(aValue).localeCompare(bValue as string)
        }
        return String(bValue).localeCompare(aValue as string)
      }
    })
  }

  const getSortParams = (columnIndex: number): ThSortType => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
      defaultDirection: 'asc',
    },
    onSort: (_event: any, index: any, direction: any) => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  return (
    <div style={{ backgroundColor: 'white' }} className={className}>
      <OuterScrollContainer style={style}>
        <InnerScrollContainer>
          <Table
            variant={isCompact ? 'compact' : undefined}
            aria-label={'entity-table'}
            isStickyHeader
            className={tableClassName}
          >
            <Thead>
              <Tr>
                {columns.map((col, i) => (
                  <Th key={`${col.description}-${i}`} sort={getSortParams(i)} className='pf-m-wrap'>
                    {col.description}
                  </Th>
                ))}
                {hasActions ? <Th>{actionsLabel}</Th> : null}
              </Tr>
            </Thead>
            <Tbody>
              {sortedEntities.map((instance, i) => (
                <Tr
                  key={`${instance[itemKeyName]}-${i}`}
                  isClickable={isClickable}
                  onRowClick={() => isClickable && onRowClick(instance)}
                  isRowSelected={selected && instance[itemKeyName] === selected[itemKeyName]}
                >
                  {columns.map((col, i) => (
                    <Td dataLabel={col.description} key={`${col.description}-${i}`}>
                      {col.formatter ? col.formatter(instance[col.key]) : instance[col.key]}
                    </Td>
                  ))}
                  {hasActions ? (
                    <Td modifier='nowrap' dataLabel={actionsLabel}>
                      <Split>
                        {onClickDelete && (
                          <SplitItem>
                            <Remove2Icon
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickDelete(instance)}
                            />
                          </SplitItem>
                        )}
                        {onClickEdit && (
                          <SplitItem>
                            <EditIcon
                              className='pf-v5-u-mx-sm'
                              title='Editar'
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickEdit(instance)}
                            />
                          </SplitItem>
                        )}
                      </Split>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InnerScrollContainer>
      </OuterScrollContainer>
    </div>
  )
}

export default EntityTable

import { Spinner } from '@patternfly/react-core'
import { range } from 'd3-array'
import { CSSProperties, useContext } from 'react'
import MastContext from '../../../contexts/MastContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { plot_color_scale_200 } from '../../../utils/colorScale'
import { defaultConfig } from '../../../utils/plotly'
import Plot from '../../shared/Plot'
import useData from '../useData'

const config = { ...defaultConfig }
const style: CSSProperties = {
  minHeight: '10rem',
  height: calcVerticalHeight({ gridSize: 4, elementSize: 3 }),
  width: '100%',
}

const PlotWindRose = ({ dtIn, dtFin, revision }: { dtIn: Date; dtFin: Date; revision: number }) => {
  const { mast, sensors: allSensors } = useContext(MastContext)
  const { mastDataBySensor, dataIsLoading } = useData(dtIn, dtFin)
  const windDirectionSensors = allSensors.filter(s => s.type === 'WDR')

  const windRoseLayout = {
    polar: {
      gridshape: 'linear',
      radialaxis: {
        angle: 45,
        tickangle: 45,
        type: 'linear',
      },
      angularaxis: {
        thetaunit: 'degrees',
        direction: 'clockwise',
        period: 6,
      },
    },
    legend: {
      x: 0.5,
      y: 1.15,
      xanchor: 'center',
      yanchor: 'bottom',
    },
  }

  if (dataIsLoading) return null

  const directionData = windDirectionSensors
    .flatMap((sensor, i) => {
      const mastData = mastDataBySensor.get(sensor.id)
      if (!mastData) {
        return null
      }

      const step = 2
      const bins = range(0, 360, step)
      const histogram = bins.map(b => 0)
      let total = 0

      mastData.forEach(md => {
        const bin = Math.trunc(md.avg / step)
        histogram[bin] = histogram[bin] + 1
        total = total + 1
      })

      const sensorColor: string = plot_color_scale_200[i]

      return [
        {
          type: 'scatterpolar',
          name: `${sensor.label} - AVG`,
          r: histogram.map(i => (i / total) * 100),
          theta: bins,
          fill: 'toself',
          line: {
            color: sensorColor,
          },
        },
      ]
    })
    .filter(d => d)

  if (directionData.length === 0)
    return <Spinner className='pf-v5-u-m-xl' size='xl' aria-label='Loading' />

  return (
    <div style={style}>
      <Plot
        key={mast.id}
        useResizeHandler
        data={directionData}
        layout={windRoseLayout}
        config={config}
        style={style}
        revision={revision}
      />
    </div>
  )
}
export default PlotWindRose

import {
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Grid,
  GridItem,
  Panel,
  PanelMain,
  PanelMainBody,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { EnergyBridgePage } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import api, { queryClient } from '../../services/api'
import PageFrame from '../shared/Page'
import PlotEnergyBridgeSeries from './PlotEnergyBridgeSeries'
import PlotEnergyBridgeTotals from './PlotEnergyBridgeTotals'

import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import DownloadButton from '../shared/DownloadButton'
import SelectSite from '../shared/selects/SelectSite'

const defaultQueryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 15,
}

const formatDate = (date?: Date) => date?.toISOString()?.split('T')[0]

const fetchData = async (tsIn: Date, tsFin: Date, site_id?: number) => {
  if (!site_id) return null
  let response
  const params = new URLSearchParams({
    ts_in: formatDate(tsIn) ?? '',
    ts_fin: formatDate(tsFin) ?? '',
  })
  try {
    response = await api.get(`/sites/${site_id}/availability/energy-bridge`, { params })
  } catch (error) {
    console.log(error, response)
  }
  if (!response?.data) {
    return null
  }
  return response.data as EnergyBridgePage
}

const EnergyBridge = () => {
  const { site, sites, isLoading: siteIsLoading } = useContext(SitesContext)
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const dtNow = new Date()
  const maxDate = new Date(dtNow.getFullYear(), dtNow.getMonth() - 1, 1)

  const [tsIn, setTsIn] = useState(new Date(dtNow.getFullYear(), dtNow.getMonth() - 3, 1))
  const [tsFin, setTsFin] = useState(maxDate)
  const [energyBridgeData, setEnergyBridgeData] = useState<EnergyBridgePage>({
    totals: {
      actual_gwh: null,
      budget_gwh: null,
      electrical_eff_gwh: null,
      energy_av_gwh: null,
      others_gwh: null,
      power_perf_gwh: null,
      wind_regime_gwh: null,
    },
    series: [],
  })

  const { isLoading: dataIsLoading, data: energyBridge } = useQuery(
    ['EnergyBridgePlot', site?.site_id, tsIn, tsFin],
    async () => fetchData(tsIn, tsFin, site?.site_id),
    { ...defaultQueryOptions, enabled: !siteIsLoading && !!site?.site_id }
  )

  const prefetch = async (tsIn: Date, tsFin: Date, site_id?: number) => {
    if (!site_id) return

    await queryClient.prefetchQuery(
      ['EnergyBridgePlot', site_id, tsIn, tsFin],
      async () => fetchData(tsIn, tsFin, site_id),
      defaultQueryOptions
    )
  }

  const isLoading = energyBridgeData.series.length === 0 || siteIsLoading

  const isContentLoading = dataIsLoading && energyBridgeData.series.length !== 0

  useEffect(() => {
    if (dataIsLoading || siteIsLoading || !energyBridge) return

    setEnergyBridgeData(energyBridge)
  })

  useEffect(() => {
    if (isLoading || !site?.site_id) return
    sites.filter(s => s.site_id !== site?.site_id).forEach(s => prefetch(tsIn, tsFin, s.site_id))
  }, [isLoading])

  const categoryExplanations = [
    {
      name: 'Budget',
      explanation: `
      This value considers the seasonalized P50 considered in the budget.
    `,
    },
    {
      name: 'Wind Regime',
      explanation: `
        The delta indicates the impact of actual wind resource on the 
        energy production in the budget.
    `,
    },
    {
      name: 'Electrical Efficiency',
      explanation: `
        The delta indicates the deviation of losses in excess
        of those modelled by the engineering team. This captures losses between the turbine
        and the center of gravity.
    `,
    },
    {
      name: 'Energy-Based Availability',
      explanation: `
        The delta indicates the deviation of losses in
        excess of the those modelled by the engineering team. This account for turbine and
        BOP availability and losses related to grid curtailment.
    `,
    },
    {
      name: 'Power Performance',
      explanation: `
        The delta indicates the deviation of losses in the power
        curve excess of those modelled by the engineering team.
    `,
    },
    {
      name: 'Others',
      explanation:
        site?.site_id === 2
          ? 'This category considers downtime losses due to Wind Sector Management.'
          : 'This category considers losses that are not included in the other categories.',
    },
    { name: 'Actual', explanation: 'This indicates actual production in relation to the budget.' },
  ]

  return (
    <PageFrame
      pageName='Energy Bridge'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      isContentLoading={isContentLoading}
      filters={[
        <SelectSite key='SelectSite' enableUrlParams overwriteQueryParams />,
        <DatetimeRangePicker
          key={`${tsIn}_${tsFin}_${site?.site_name}`}
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart: Date, newEnd: Date) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='month'
          maxEnabled={maxDate}
          allowEqualDates={true}
        />,
        <DownloadButton
          key={`dl_btn_${site?.site_name}`}
          label='Exportar'
          url={`/sites/${site?.site_id}/availability/energy-bridge/report?${new URLSearchParams({
            date_in: formatDate(tsIn) || '',
            date_fin: formatDate(tsFin) || '',
          })}`}
          filename={`EnergyBridge_${site?.site_name}.xlsx`}
        />,
      ]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Grid hasGutter>
              <GridItem lg={8}>
                {!isLoading && (
                  <PlotEnergyBridgeTotals
                    energyBridge={energyBridgeData}
                    revision={(isNavOpen as unknown as number) + 0}
                    isLoading={isLoading}
                  />
                )}
                {!isLoading && (
                  <PlotEnergyBridgeSeries
                    energyBridge={energyBridgeData}
                    revision={(isNavOpen as unknown as number) + 0}
                    isLoading={isLoading}
                  />
                )}
              </GridItem>
              <GridItem lg={4}>
                <TextContent>
                  <Text component={TextVariants.h1} className='pf-v5-u-m-md pf-v5-u-m-xl-on-lg'>
                    Energy Bridge Balance
                  </Text>
                </TextContent>
                <DataList aria-label='energy-bridge-data-list'>
                  {categoryExplanations.map((exp, i) => (
                    <DataListItem key={i}>
                      <DataListItemRow>
                        <DataListItemCells
                          dataListCells={[
                            <DataListCell width={1} key={1}>
                              <TextContent>
                                <Text component={TextVariants.p}>
                                  <b>{exp.name}:</b> {exp.explanation}
                                </Text>
                              </TextContent>
                            </DataListCell>,
                          ]}
                        />
                      </DataListItemRow>
                    </DataListItem>
                  ))}
                </DataList>
              </GridItem>
            </Grid>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </PageFrame>
  )
}

export default EnergyBridge

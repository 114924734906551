import { Panel, PanelMain, PanelMainBody, Split } from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'
import { SignalTypes } from '../../../client'
import DatetimeRangePicker from '../../../components/calendar/DatetimeRangePicker'
import MastContext from '../../../contexts/MastContext'
import Page from '../../shared/Page'
import SelectMasts from '../SelectMasts'
import useData from '../useData'
import PlotSignal from './PlotSignal'
import PlotWindRose from './PlotWindRose'

const DT_NOW = new Date()

const DataViz = () => {
  const { mast, isLoading: mastIsLoading, sensors, signalTypes } = useContext(MastContext)
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const signalTypesOnMast: SignalTypes[] = []
  signalTypes.forEach(st => {
    sensors.forEach(s => {
      if (st.type === s.type && !signalTypesOnMast.some(item => item.type === st.type)) {
        signalTypesOnMast.push(st)
      }
    })
  })

  const [revision, setRevision] = useState(1)
  const [tsFin, setTsFin] = useState(new Date(mast.dataset_end))
  const [tsIn, setTsIn] = useState(
    new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDay() - 7)
  )
  useEffect(() => {
    if (mast.id === 0) return

    const dtFin = new Date(mast.dataset_end)
    const dtIn = new Date(dtFin.getFullYear(), dtFin.getMonth(), dtFin.getDay() - 7)

    setTsFin(dtFin)
    setTsIn(dtIn)
  }, [mast])

  useEffect(() => setRevision(revision + 1), [isNavOpen])

  const { dataIsLoading } = useData(tsIn, tsFin)

  return (
    <Page
      pageName='Data Viz'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isContentLoading={dataIsLoading}
      isLoading={mastIsLoading}
      filters={[
        <SelectMasts key='SelectMasts' />,
        <DatetimeRangePicker
          key='DateSelect'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart: Date, newEnd: Date) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='date'
          maxEnabled={DT_NOW}
        />,
      ]}
    >
      {signalTypesOnMast.map((s, i) => (
        <Panel key={`${s.id}_${i}_${revision}`} className='pf-v5-u-box-shadow-sm pf-v5-u-my-md'>
          <PanelMain>
            <PanelMainBody>
              {s.type === 'WDR' ? (
                <>
                  <b>{s.description}</b>
                  <Split>
                    <PlotSignal dtIn={tsIn} dtFin={tsFin} signalType={s} revision={revision} />
                    <PlotWindRose dtIn={tsIn} dtFin={tsFin} revision={revision} />
                  </Split>
                </>
              ) : (
                <>
                  <b>{s.description}</b>
                  <PlotSignal dtIn={tsIn} dtFin={tsFin} signalType={s} revision={revision} />
                </>
              )}
            </PanelMainBody>
          </PanelMain>
        </Panel>
      ))}
    </Page>
  )
}

export default DataViz

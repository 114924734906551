/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailabilityCounterDataTable } from '../models/AvailabilityCounterDataTable';
import type { AvailabilityMonthly } from '../models/AvailabilityMonthly';
import type { AvailabilityParkMonthly } from '../models/AvailabilityParkMonthly';
import type { AvailabilityPCReclassification } from '../models/AvailabilityPCReclassification';
import type { AvailabilityPCReclassificationPointsTimeseries } from '../models/AvailabilityPCReclassificationPointsTimeseries';
import type { AvailabilityPowerCurve } from '../models/AvailabilityPowerCurve';
import type { AvailabilityPowerCurveEvents } from '../models/AvailabilityPowerCurveEvents';
import type { AvailabilityRealtimePark } from '../models/AvailabilityRealtimePark';
import type { AvailabilityTimeSeries } from '../models/AvailabilityTimeSeries';
import type { AvailabilityTurbineMonthly } from '../models/AvailabilityTurbineMonthly';
import type { CounterDataTemporalDaily } from '../models/CounterDataTemporalDaily';
import type { CounterDataTemporalDailyPark } from '../models/CounterDataTemporalDailyPark';
import type { CounterDataTemporalDailyTurb } from '../models/CounterDataTemporalDailyTurb';
import type { CounterDataTemporalMonthly } from '../models/CounterDataTemporalMonthly';
import type { CounterDataTemporalMonthlyPark } from '../models/CounterDataTemporalMonthlyPark';
import type { CounterDataTemporalMonthlyTurb } from '../models/CounterDataTemporalMonthlyTurb';
import type { EnergyBridgePage } from '../models/EnergyBridgePage';
import type { EnergyExpected } from '../models/EnergyExpected';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AvailabilityAPI {

  /**
   * Get Counters Monthly
   * @returns CounterDataTemporalMonthly Successful Response
   * @throws ApiError
   */
  public static getCountersMonthly({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<CounterDataTemporalMonthly>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters/monthly',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Counters Monthly Parks
   * @returns CounterDataTemporalMonthlyPark Successful Response
   * @throws ApiError
   */
  public static getCountersMonthlyParks({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<CounterDataTemporalMonthlyPark>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters/monthly/parks',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Counters Monthly Turbs
   * @returns CounterDataTemporalMonthlyTurb Successful Response
   * @throws ApiError
   */
  public static getCountersMonthlyTurbs({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<CounterDataTemporalMonthlyTurb>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters/monthly/turbines',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Counters Daily
   * @returns CounterDataTemporalDaily Successful Response
   * @throws ApiError
   */
  public static getCountersDaily({
    siteId,
    dateIn,
    dateFin,
    deltaMonths,
  }: {
    siteId: number,
    dateIn?: (string | null),
    dateFin?: (string | null),
    deltaMonths?: (number | null),
  }): CancelablePromise<Array<CounterDataTemporalDaily>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters/daily',
      path: {
        'site_id': siteId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'delta_months': deltaMonths,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Counters Daily Parks
   * @returns CounterDataTemporalDailyPark Successful Response
   * @throws ApiError
   */
  public static getCountersDailyParks({
    siteId,
    dateIn,
    dateFin,
    deltaMonths,
  }: {
    siteId: number,
    dateIn?: (string | null),
    dateFin?: (string | null),
    deltaMonths?: (number | null),
  }): CancelablePromise<Array<CounterDataTemporalDailyPark>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters/daily/parks',
      path: {
        'site_id': siteId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'delta_months': deltaMonths,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Counters Daily Turbs
   * @returns CounterDataTemporalDailyTurb Successful Response
   * @throws ApiError
   */
  public static getCountersDailyTurbs({
    siteId,
    dateIn,
    dateFin,
    deltaMonths,
  }: {
    siteId: number,
    dateIn?: (string | null),
    dateFin?: (string | null),
    deltaMonths?: (number | null),
  }): CancelablePromise<Array<CounterDataTemporalDailyTurb>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters/daily/turbines',
      path: {
        'site_id': siteId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'delta_months': deltaMonths,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Realtime
   * @returns AvailabilityRealtimePark Successful Response
   * @throws ApiError
   */
  public static getRealtime({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<AvailabilityRealtimePark> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/real-time',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Monthly
   * @returns AvailabilityMonthly Successful Response
   * @throws ApiError
   */
  public static getMonthly({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<AvailabilityMonthly>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/monthly',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Availability Monthly Parks
   * @returns AvailabilityParkMonthly Successful Response
   * @throws ApiError
   */
  public static getAvailabilityMonthlyParks({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<AvailabilityParkMonthly>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/monthly/parks',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Monthly Turbs
   * @returns AvailabilityTurbineMonthly Successful Response
   * @throws ApiError
   */
  public static getMonthlyTurbs({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<AvailabilityTurbineMonthly>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/monthly/turbines',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Energy Bridge
   * @returns EnergyBridgePage Successful Response
   * @throws ApiError
   */
  public static getEnergyBridge({
    siteId,
    tsIn,
    tsFin,
    deltaDays,
  }: {
    siteId: number,
    tsIn?: (string | null),
    tsFin?: (string | null),
    deltaDays?: (number | null),
  }): CancelablePromise<EnergyBridgePage> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/energy-bridge',
      path: {
        'site_id': siteId,
      },
      query: {
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Counters
   * @returns AvailabilityCounterDataTable Successful Response
   * @throws ApiError
   */
  public static getCounters({
    siteId,
    dateIn,
    dateFin,
    deltaMonths,
  }: {
    siteId: number,
    dateIn?: (string | null),
    dateFin?: (string | null),
    deltaMonths?: (number | null),
  }): CancelablePromise<AvailabilityCounterDataTable> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters',
      path: {
        'site_id': siteId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'delta_months': deltaMonths,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Power Curve
   * @returns AvailabilityPowerCurve Successful Response
   * @throws ApiError
   */
  public static getPowerCurve({
    siteId,
    year,
  }: {
    siteId: number,
    year: number,
  }): CancelablePromise<Array<AvailabilityPowerCurve>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/power-curve',
      path: {
        'site_id': siteId,
      },
      query: {
        'year': year,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Timeseries
   * @returns AvailabilityTimeSeries Successful Response
   * @throws ApiError
   */
  public static getTimeseries({
    siteId,
    dateIn,
    dateFin,
    turbIds,
  }: {
    siteId: number,
    dateIn: string,
    dateFin: string,
    turbIds?: (Array<number> | null),
  }): CancelablePromise<Array<AvailabilityTimeSeries>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/timeseries',
      path: {
        'site_id': siteId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'turb_ids': turbIds,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Energy Expected
   * @returns EnergyExpected Successful Response
   * @throws ApiError
   */
  public static getEnergyExpected({
    siteId,
    tsIn,
    tsFin,
  }: {
    siteId: number,
    tsIn: string,
    tsFin: string,
  }): CancelablePromise<EnergyExpected> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/energy-expected',
      path: {
        'site_id': siteId,
      },
      query: {
        'ts_in': tsIn,
        'ts_fin': tsFin,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getReport({
    siteId,
    mm,
    yyyy,
  }: {
    siteId: number,
    mm?: number,
    yyyy?: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'mm': mm,
        'yyyy': yyyy,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Counters Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getCountersReport({
    siteId,
    tsIn,
    tsFin,
    deltaDays,
  }: {
    siteId: number,
    tsIn?: (string | null),
    tsFin?: (string | null),
    deltaDays?: (number | null),
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/counters/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Energy Bridge Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getEnergyBridgeReport({
    siteId,
    dateIn,
    dateFin,
    deltaMonths,
  }: {
    siteId: number,
    dateIn?: (string | null),
    dateFin?: (string | null),
    deltaMonths?: (number | null),
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/energy-bridge/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'delta_months': deltaMonths,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Energy Expected Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getEnergyExpectedReport({
    siteId,
    dateIn,
    dateFin,
    deltaMonths,
  }: {
    siteId: number,
    dateIn?: (string | null),
    dateFin?: (string | null),
    deltaMonths?: (number | null),
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/energy-expected/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'delta_months': deltaMonths,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Pc Reclassification
   * @returns AvailabilityPowerCurveEvents Successful Response
   * @throws ApiError
   */
  public static getAllPcReclassification({
    siteId,
    yyyy,
    mm,
  }: {
    siteId: number,
    yyyy?: number,
    mm?: number,
  }): CancelablePromise<Array<AvailabilityPowerCurveEvents>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/power-curve/reclassification',
      path: {
        'site_id': siteId,
      },
      query: {
        'yyyy': yyyy,
        'mm': mm,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Pc Reclassification
   * @returns AvailabilityPCReclassification Successful Response
   * @throws ApiError
   */
  public static updatePcReclassification({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: AvailabilityPCReclassification,
  }): CancelablePromise<AvailabilityPCReclassification> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sites/{site_id}/availability/power-curve/reclassification',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Points Pc Reclassification
   * @returns AvailabilityPCReclassificationPointsTimeseries Successful Response
   * @throws ApiError
   */
  public static getPointsPcReclassification({
    siteId,
    eventId,
    turbId,
    tsIn,
    tsFin,
    deltaDays,
  }: {
    siteId: number,
    eventId: string,
    turbId: number,
    tsIn?: (string | null),
    tsFin?: (string | null),
    deltaDays?: (number | null),
  }): CancelablePromise<Array<AvailabilityPCReclassificationPointsTimeseries>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/availability/power-curve/reclassification/{event_id}/points',
      path: {
        'site_id': siteId,
        'event_id': eventId,
      },
      query: {
        'turb_id': turbId,
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Pc Reclassification
   * @returns string Successful Response
   * @throws ApiError
   */
  public static deletePcReclassification({
    siteId,
    eventId,
  }: {
    siteId: number,
    eventId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/sites/{site_id}/availability/power-curve/reclassification/{event_id}',
      path: {
        'site_id': siteId,
        'event_id': eventId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}

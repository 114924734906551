import { Divider, Grid, GridItem, Panel, PanelHeader, PanelMain } from '@patternfly/react-core'
import { CSSProperties, useContext, useEffect, useState } from 'react'
import { MastIngestionSummary, MastsAPI, SignalTypes } from '../../../client'
import EntityTable from '../../../components/EntityTable'
import MastContext from '../../../contexts/MastContext'
import useVertical from '../../../hooks/useVertical'
import { queryClient } from '../../../services/api'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { fmtDateStringToBRLDateString, formatDateWithoutTime } from '../../../utils/formatDate'
import Page from '../../shared/Page'
import LoaderSpinner from '../../shared/Page/LoaderSpinner'
import useData, { QUERY_OPTIONS } from '../useData'
import PlotSignal from './PlotSignal'
import PlotWindRose from './PlotWindRose'

const panelStyle: CSSProperties = {
  height: calcVerticalHeight({ gridSize: 1, elementSize: 1, pgHasFilter: false }),
  width: '100%',
}

const panelMainStyle: CSSProperties = {
  height: calcVerticalHeight({ gridSize: 1, elementSize: 1, pgHasFilter: true }),
  overflowY: 'auto',
}

const fmtPercentage = (n: number) => {
  return String(Number(n.toFixed(2)) * 100)
}

const MastsSummary = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const [revision, setRevision] = useState(1)
  const {
    summaries,
    isLoading: summaryIsLoading,
    setActiveMast,
    mast,
    allMasts,
    sensors,
    signalTypes,
  } = useContext(MastContext)

  const signalTypesOnMast: SignalTypes[] = []
  signalTypes.forEach(st => {
    sensors.forEach(s => {
      if (st.type === s.type && !signalTypesOnMast.some(item => item.type === st.type)) {
        signalTypesOnMast.push(st)
      }
    })
  })

  const tsFin = new Date(mast.dataset_end)
  const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)

  const { dataIsLoading, mastDataBySensor, mastData } = useData(tsIn, tsFin)

  const handleSelectSummary = (mastSummary: MastIngestionSummary) => {
    const _mastIndex = allMasts.findIndex(m => m.id === mastSummary.mast_id)
    const _mast = allMasts[_mastIndex]
    setActiveMast(_mast)
  }

  useEffect(() => setRevision(revision + 1), [mast, isNavOpen, dataIsLoading])

  return (
    <Page
      pageName='Resumo das Torres'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={summaryIsLoading}
      isContentLoading={dataIsLoading}
    >
      <Grid hasGutter lg={4}>
        <GridItem>
          <Panel style={panelStyle} className='pf-v5-u-box-shadow-md'>
            <PanelHeader>
              <b>Torres</b>
            </PanelHeader>
            <Divider />
            <PanelMain>
              <EntityTable<MastIngestionSummary>
                items={summaries}
                isLoading={summaryIsLoading}
                itemKeyName='mast_id'
                selected={summaries.find(s => s.mast_id === mast.id)}
                onSelect={handleSelectSummary}
                isCompact
                columnConfig={[
                  { key: 'mast_name', description: 'Nome' },
                  {
                    key: 'dados_ate',
                    description: 'Dados Até',
                    formatter: fmtDateStringToBRLDateString,
                  },
                  { key: 'dias_sem', description: 'Dias Sem' },
                  { key: 'cobertura', description: 'Cobertura', formatter: fmtPercentage },
                ]}
              />
            </PanelMain>
          </Panel>
        </GridItem>
        <GridItem lg={8}>
          <Panel style={panelStyle} className='pf-v5-u-box-shadow-md'>
            <PanelHeader>
              <b>Medições</b>
            </PanelHeader>
            <Divider />
            {dataIsLoading || !mastData || sensors.length === 0 ? (
              <LoaderSpinner />
            ) : (
              <PanelMain style={panelMainStyle}>
                <Grid>
                  <GridItem span={6} rowSpan={2}>
                    <PlotWindRose mastDataBySensor={mastDataBySensor} revision={revision} />
                  </GridItem>
                  {signalTypesOnMast.map((sigType, i) => (
                    <GridItem key={`${sigType.id}-${i}`} lg={6}>
                      <PlotSignal
                        mastDataBySensor={mastDataBySensor}
                        signalType={sigType}
                        revision={revision}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </PanelMain>
            )}
          </Panel>
        </GridItem>
      </Grid>
    </Page>
  )
}

export default MastsSummary
